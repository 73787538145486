import { checkForUnauthorizedResponse } from '../../utils/axios';

import { getLocaleLanguage } from 'utils/utils';

export const getLocaleTranslationsThunk = async (_: any, thunkAPI: any) => {
	const lang = getLocaleLanguage();

	try {
		const response = await fetch(
			`${process.env.REACT_APP_ASSET_URL}i18n/latest/providerportal/${lang}`,
		);

		const res = await response.json();
		if (!res) throw new Error('No texts');
		return res;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
