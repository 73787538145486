import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Paper from 'styles/paper/paper';
import Stack from 'styles/stack/Stack';
import CompositeTypography from 'styles/typography/CompositeTypography.styles';
import ClaimStatusBreadcrumbs from './ClaimStatusBreadcrumbs.component';

const ClaimMemberDetails = () => {
	const theme = useTheme();
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));
	const { claimData: claim } = useSelector((store: RootState) => store.claim);
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const { t: tDbLabels } = useTranslation('', { keyPrefix: 'dbLabels' });

	return (
		<Paper
			elevation={0}
			sx={{
				p: 2.5,
				justifyContent: 'space-between',
				display: 'flex',
				borderRadius: 0.5,
				width: !!desktop ? '1344px' : '100%',
				maxWidth: '1344px',
			}}
		>
			<Stack flexGrow={1} rowGap={1.875}>
				<CompositeTypography label={tClientLabels('claim.labels.memberFullName')} value={claim?.memberFullName as string} />
				<CompositeTypography label={tClientLabels('claim.labels.memberId')} value={claim?.memberId as string} />
				<CompositeTypography label={tClientLabels('claim.labels.memberDesignation')} value={tDbLabels(claim?.memberDesignationLabel as string)} />
			</Stack>
			<Stack flexGrow={0} width={'auto'} rowGap={1.875}>
				<CompositeTypography label={tClientLabels('claim.labels.planName')} value={claim?.planName as string} />
				<CompositeTypography label={tClientLabels('claim.labels.memberProviderLocation')} value={claim?.memberProviderLocation as string} />
				<CompositeTypography label={tClientLabels('claim.labels.status')} value={claim?.claimStatus as string} children={<ClaimStatusBreadcrumbs claim={claim} />} />
			</Stack>
		</Paper>
	);
};

export default ClaimMemberDetails;
