import { FC, Fragment } from 'react';
import { BoxOverflow, IconButton, MarkdownRenderer } from 'styles';

import { ReactComponent as CloseDialog } from 'assett/icons/close_dialog_icon.svg';
import useDialogConfirm from 'hooks/DialogConfirm.hook';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Button from 'styles/button/Button';
import Dialog from 'styles/dialog/Dialog';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';

const DialogConfirm: FC = () => {
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const dialogConfirm = useDialogConfirm();

	const {
		open,
		title,
		content,
		caption,
		messageCode,
		labelCancel,
		labelConfirm,
		variantButtonCancel,
		multi,
		data,
		buttonClose,
	} = useSelector((store: RootState) => store.hooks.dialog);
	const { errorMessages } = useSelector(
		(store: RootState) => store.textsLocales,
	);
	const { type } = useSelector((store: RootState) => store.hooks.dialog);

	const contentMessage = content
		? content
		: errorMessages && messageCode && errorMessages[messageCode]
		? errorMessages[messageCode]
		: tClientLabels('dialog.confirm.error.content');

	return (
		<Dialog
			type={type}
			open={open}
			close={
				buttonClose ? (
					<IconButton onClick={() => dialogConfirm.close()}>
						<CloseDialog />
					</IconButton>
				) : null
			}
			variant="confirm"
			title={title}
			events={!!labelCancel || !!labelConfirm}
			actions={
				<Fragment>
					{labelCancel ? (
						<Button
							variant={variantButtonCancel}
							onClick={() => dialogConfirm.cancel()}
						>
							{labelCancel}
						</Button>
					) : null}
					{labelConfirm ? (
						<Button variant="contained" onClick={() => dialogConfirm.confirm()}>
							{labelConfirm}
						</Button>
					) : null}
				</Fragment>
			}
		>
			{!multi ? (
				<Stack gap={'20px'}>
					<Typography variant="body">
						{<MarkdownRenderer>{contentMessage}</MarkdownRenderer>}
					</Typography>
					{caption && (
						<Typography variant="caption" color={theme => theme.palette.grey1.main}>
							{caption}
						</Typography>
					)}
				</Stack>
			) : (
				<BoxOverflow variant="y" sx={{ gap: '20px' }}>
					{data.map((e: any, index) => (
						<Stack key={index} gap={'16px'}>
							<Typography variant="title6">{e.title}</Typography>
							<Typography variant="body">
								<MarkdownRenderer>{e.content}</MarkdownRenderer>
							</Typography>
							{caption && (
								<Typography variant="caption" color={theme => theme.palette.grey1.main}>
									{e.caption}
								</Typography>
							)}
						</Stack>
					))}
				</BoxOverflow>
			)}
		</Dialog>
	);
};

export default DialogConfirm;
