import { Trans } from 'react-i18next';
import { getFullDate, getShortDate } from 'utils/utils';

import useDevice from 'hooks/DesktopDevice.hook';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Paper from 'styles/paper/paper';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';

const MemberDetail: FC = () => {
	const { isDesktop } = useDevice();
	const { member } = useSelector((store: RootState) => store.member);

	return (
		<Paper
			elevation={0}
			sx={{
				p: 2.5,
				width: isDesktop ? 1022 : '100%',
				justifyContent: 'space-between',
				display: 'flex',
				borderRadius: 0.5,
			}}
		>
			<Stack flexGrow={1} rowGap={1.875}>
				<Typography variant="body">
					<Trans
						i18nKey={'clientLabels.memberDetail.labels.memberName'}
						values={{ memberName: member?.memberName }}
						components={{
							bold: (
								<Typography
									sx={{ ml: 1 }}
									variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
								/>
							),
						}}
					/>
				</Typography>
				<Typography variant="body">
					<Trans
						i18nKey={'clientLabels.memberDetail.labels.carrier'}
						values={{ carrier: member?.carrierName }}
						components={{
							bold: (
								<Typography
									sx={{ ml: 1 }}
									variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
								/>
							),
						}}
					/>
				</Typography>
				<Typography variant="body">
					<Trans
						i18nKey={'clientLabels.memberDetail.labels.dateOfService'}
						values={{
							dateOfService: getShortDate(member?.dateOfService),
						}}
						components={{
							bold: (
								<Typography
									sx={{ ml: 1 }}
									variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
								/>
							),
						}}
					/>
				</Typography>
			</Stack>
			<Stack flexGrow={0} width={'50%'} rowGap={1.875}>
				<Typography variant="body">
					<Trans
						i18nKey={'clientLabels.memberDetail.labels.fiscalCode'}
						values={{ fiscalCode: member?.fiscalCode }}
						components={{
							bold: (
								<Typography
									sx={{ ml: 1 }}
									variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
								/>
							),
						}}
					/>
				</Typography>
				<Typography variant="body">
					<Trans
						i18nKey={'clientLabels.memberDetail.labels.groupName'}
						values={{ groupName: member?.groupName }}
						components={{
							bold: (
								<Typography
									sx={{ ml: 1 }}
									variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
								/>
							),
						}}
					/>
				</Typography>
				<Typography variant="body">
					<Trans
						i18nKey={'clientLabels.memberDetail.labels.dateGenerated'}
						values={{
							dateGenerated: getFullDate(member?.dateGenerated),
						}}
						components={{
							bold: (
								<Typography
									sx={{ ml: 1 }}
									variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
								/>
							),
						}}
					/>
				</Typography>
			</Stack>
		</Paper>
	);
};

export default MemberDetail;
