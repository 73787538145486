import { toggleExpandView } from 'feature/documents/documentsSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import {
	Box,
	BoxOverflow,
	Button,
	CircularProgress,
	IconButton,
	Stack,
	Typography,
} from 'styles';
import palette from 'styles/theme/Palette';
import { IDocumentStructure, MIME_TYPES } from 'types/Document';
import { ReactComponent as ChevronLeft } from '../../assett/icons/chevron_left_icon.svg';
import { ReactComponent as ChevronRight } from '../../assett/icons/chevron_right_icon.svg';
import { ReactComponent as DownloadIcon } from '../../assett/icons/download.svg';

import useDialogConfirm, { GENERIC_ERROR } from 'hooks/DialogConfirm.hook';
import { useFileOpener } from 'hooks/downloader.hook';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = '/plugins/pdfjs/pdf.worker.min.js';

type Props = {
	selectedDocument: IDocumentStructure;
};

const DocumentRenderer = ({ selectedDocument }: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const fileOpener = useFileOpener();
	const dialogConfirm = useDialogConfirm();

	const { expandView } = useSelector((store: RootState) => store.documentation);
	const [numPages, setNumPages] = useState<number>();
	const { url, name, mime } =
		selectedDocument?.attributes?.attachment?.data[0].attributes;
	const URL_RAW = `${process.env.REACT_APP_API_URL}/cms${url}`;

	const downloadDoc = () => {
		if (url) {
			fileOpener(URL_RAW, name);
			return;
		}

		dialogConfirm.open(GENERIC_ERROR);
	};

	const handleClickToggleList = () => {
		dispatch(toggleExpandView());
	};

	const getFileRender = () => {
		// check for PDFs
		if (MIME_TYPES.PDF.includes(mime)) {
			return (
				<Document
					file={URL_RAW}
					loading={
						<Box display={'flex'} width={'100%'} justifyContent={'center'} py={'8px'}>
							<CircularProgress />
						</Box>
					}
					onLoadSuccess={({ numPages }) => setNumPages(numPages)}
				>
					{Array.apply(null, Array(numPages))
						.map((_, i) => i + 1)
						.map((page, pageIndex) => (
							<Page
								key={pageIndex}
								renderTextLayer={false}
								renderAnnotationLayer={false}
								pageNumber={page}
							/>
						))}
				</Document>
			);
		}

		// check for images
		if (MIME_TYPES.IMAGE.includes(mime)) {
			return <img alt={name} src={URL_RAW} height={'100%'} width={'100%'} />;
		}

		// all the rest
		return (
			<Typography variant="body">
				{tClientLabels('commons.noPreviewAvailable')}
			</Typography>
		);
	};

	return (
		<Stack borderRadius={'4px'} bgcolor={palette.grey5.main} maxHeight={'100%'}>
			<Box
				sx={{
					borderTopRightRadius: '4px',
					borderTopLeftRadius: '4px',
				}}
				height={'52px'}
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
				width={'100%'}
				py={'10px'}
				px={'20px'}
				bgcolor={palette.black.light}
			>
				<Button
					variant="text"
					sx={{
						paddingX: 0,
						paddingY: '10px',
						gap: '5px',
						textTransform: 'capitalize',
						width: {
							sm: '80px',
							lg: '84px',
						},
					}}
					startIcon={expandView ? <ChevronLeft /> : null}
					endIcon={!expandView ? <ChevronRight /> : null}
					onClick={handleClickToggleList}
				>
					{expandView ? (
						<>{tClientLabels('commons.expand')}</>
					) : (
						<>{tClientLabels('commons.collapse')}</>
					)}
				</Button>
				<Typography variant="bodyLink">
					{selectedDocument.attributes.title}
				</Typography>
				<Box
					display={'flex'}
					flexDirection={'row'}
					justifyContent={'flex-end'}
					sx={{
						width: {
							sm: '80px',
							lg: '84px',
						},
					}}
				>
					<IconButton disableRipple disableTouchRipple onClick={downloadDoc}>
						<DownloadIcon width={24} height={24} color={palette.primary.main} />
					</IconButton>
				</Box>
			</Box>
			<BoxOverflow
				variant="y"
				sx={{ marginX: '20px', marginY: '15px', paddingRight: '8px' }}
			>
				<Box display={'flex'} justifyContent={'center'}>
					<Box mx={'auto'}>{getFileRender()}</Box>
				</Box>
			</BoxOverflow>
		</Stack>
	);
};

export default DocumentRenderer;
