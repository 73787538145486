import { FC, useEffect, useState } from 'react';
import { InputLabel, ListItemText, MenuItem, Radio, Select } from 'styles';
import { SelectChangeEvent, styled } from '@mui/material';

import FormControl from 'styles/form/FormControl';
import { SelectProps } from 'styles/select/Select';
import { useTranslation } from 'react-i18next';
import palette from 'styles/theme/Palette';

const StyledSelect = styled(Select)<SelectProps>``;

interface SelectGenericProps {
	sx: any;
	name: 'claim' | 'contactUs' | 'doctorType';
	items: string[] | { value: string; text: string }[];
	action: (payload: string) => void;
	initialValue?: string;
	error?: boolean;
}

const SelectGeneric: FC<SelectGenericProps> = ({
	action,
	items,
	name,
	sx,
	initialValue,
	error,
}) => {
	const [selectedValue, setSelectedValue] = useState('');
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });

	const handlerSelectItem = (event: SelectChangeEvent<any | unknown>) => {
		setSelectedValue(event.target.value);
		action(event.target.value);
	};

	useEffect(() => {
		if (initialValue) {
			setSelectedValue(initialValue === 'reset' ? '' : initialValue);
		}
	}, [initialValue]);

	return (
		<FormControl>
			<InputLabel sx={{ color: !!error ? palette.error.main : 'inherit' }}>
				{tClientLabels(`commons.selectGenericHeader.${name}`)}
			</InputLabel>
			<StyledSelect
				sx={{ ...sx, height: '56px' }}
				multiple={false}
				variant="filled"
				name={tClientLabels(`commons.selectGenericHeader.${name}`) as string}
				value={selectedValue ?? ''}
				native={false}
				displayEmpty={true}
				renderValue={(e: any) => {
					if (typeof items === 'object') {
						const selectedItem = (items as { value: string; text: string }[]).find(
							el => el.value === e,
						);
						if (!selectedItem) return '';
						return selectedItem.text;
					} else {
						return e;
					}
				}}
				MenuProps={{ PaperProps: { sx: { maxHeight: '268px' } } }}
				onChange={handlerSelectItem}
				disableUnderline
				error={error}
			>
				{items.map(item => {
					return typeof item === 'object' ? (
						<MenuItem key={item.value} value={item.value} disableTouchRipple>
							<Radio checked={selectedValue === item.value} />
							<ListItemText primary={item.text} />
						</MenuItem>
					) : (
						<MenuItem key={item} value={item} disableTouchRipple>
							<Radio checked={selectedValue === item} />
							<ListItemText primary={item} />
						</MenuItem>
					);
				})}
			</StyledSelect>
		</FormControl>
	);
};

export default SelectGeneric;
