import Select, { SelectProps } from '../../../styles/select/Select';
import { getLanguageStorage, setLanguageStorage } from 'utils/localStorage';
import { getLocaleTranslations } from 'feature/textsLocales/textsLocalesSlice';
import { useEffect, useState } from 'react';

import { ReactComponent as ArrowDownIcon } from 'assett/icons/arrow_down_icon.svg';
import FormControl from 'styles/form/FormControl';
import { LANGUAGES } from 'types/Localization';
import { ListItemText } from '@mui/material';
import MenuItem from 'styles/menu/MenuItem';
import Radio from 'styles/radio/Radio';
import Stack from 'styles/stack/Stack';
import { getFooterLinks } from 'feature/footer/footerSlice';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

type Props = SelectProps;
const StyledSelect = styled(Select)<Props>``;

const SelectLanguage: React.FC<Props> = props => {
	const [value, setValue] = useState<string>(getLanguageStorage() as string);
	const dispatch = useDispatch<any>();

	const handleChange = (e: any) => {
		const { value } = e.target;
		setValue(value);
		setLanguageStorage(value);
		dispatch(getLocaleTranslations(''));
	};

	useEffect(() => {
		if (value) {
			dispatch(getFooterLinks(''));
		}
	}, [dispatch, value]);

	return (
		<Stack mt={{ lg: '41px', sm: '30px' }}>
			<FormControl>
				<StyledSelect
					{...props}
					size="small"
					sx={{ ...props.sx, width: 180, background: 'white' }}
					value={value.toLowerCase()}
					renderValue={() =>
						LANGUAGES.find(e => e.id === value.toLowerCase())?.label
					}
					onChange={handleChange}
					IconComponent={ArrowDownIcon}
				>
					{LANGUAGES.map(e => (
						<MenuItem key={e.id} value={e.id} disableTouchRipple>
							<Radio checked={value.toLowerCase() === e.id} />
							<ListItemText primary={e.label} />
						</MenuItem>
					))}
				</StyledSelect>
			</FormControl>
		</Stack>
	);
};

export default SelectLanguage;
