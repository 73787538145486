import { AppDispatch, RootState } from 'store/store';
import { Box, Button, IconButton, Stack, Typography } from 'styles';
import {
	addOpeningConfigRow,
	addOpeningHourRow,
	deleteOpeningConfigRow,
	setDay,
	setHour,
} from 'feature/locationInformation/locationInformationSlice';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Clock } from 'assett/icons/clock.svg';
import { ReactComponent as CloseIcon } from 'assett/icons/closing_outline_large.svg';
import { ReactComponent as OpenIcon } from 'assett/icons/open_outline.svg';
import { SelectChangeEvent } from '@mui/material';
import SelectDays from 'components/commons/form/SelectDays';
import SelectHours from 'components/commons/form/SelectHours';
import { useTranslation } from 'react-i18next';

const ChooseOpeningHour: React.FC<{ checkFields: boolean }> = ({
	checkFields,
}) => {
	const dispatch = useDispatch<AppDispatch>();
	const { openingsHour } = useSelector(
		(store: RootState) => store.locationInformation,
	);
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });

	return (
		<>
			{openingsHour.map((rowData, index) => (
				<Stack direction="column" width="100%" key={rowData.key} gap="10px">
					{rowData.openings.map((rowOpeningDays, daysIndex) => (
						<Stack
							key={rowOpeningDays.key}
							px={2}
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							gap="7.5px"
						>
							<Box
								display="flex"
								sx={{
									visibility:
										rowData.openings.length > 1 && daysIndex !== 0 ? 'hidden' : 'visible',
								}}
							>
								<Clock />
							</Box>
							<Stack
								direction="row"
								alignItems="center"
								gap="15px"
								sx={{
									width: { sm: '100%', lg: '235px' },
									visibility:
										rowData.openings.length > 1 && daysIndex !== 0 ? 'hidden' : 'visible',
								}}
							>
								<Box display="flex" width="100%">
									<SelectDays
										label={tClientLabels(
											'location-information.chooseOpeningsHour.openingDays',
										)}
										handleSelectedDays={(event: SelectChangeEvent<any | unknown>) => {
											dispatch(setDay({ days: event.target.value, dataIndex: index }));
										}}
										selectedDay={rowData.days}
										checkFields={checkFields}
									/>
								</Box>
							</Stack>
							<SelectHours
								label={tClientLabels(
									'location-information.chooseOpeningsHour.openingHour',
								)}
								handleSelectedHours={(event: SelectChangeEvent<any | unknown>) => {
									dispatch(
										setHour({
											hour: event.target.value,
											dataIndex: index,
											daysIndex,
											target: 'open',
										}),
									);
								}}
								selectedHour={rowOpeningDays.open}
								checkFields={checkFields}
							/>
							<Typography variant="value1" width="5px">
								-
							</Typography>
							<SelectHours
								label={tClientLabels(
									'location-information.chooseOpeningsHour.closingHour',
								)}
								handleSelectedHours={(event: SelectChangeEvent<any | unknown>) => {
									dispatch(
										setHour({
											hour: event.target.value,
											dataIndex: index,
											daysIndex,
											target: 'close',
										}),
									);
								}}
								selectedHour={rowOpeningDays.close}
								checkFields={checkFields}
							/>
							<Stack direction="row" alignItems="center" gap="15px">
								<IconButton
									onClick={() => dispatch(addOpeningHourRow({ dataIndex: index }))}
								>
									<OpenIcon />
								</IconButton>
								<IconButton
									onClick={() =>
										dispatch(deleteOpeningConfigRow({ dataIndex: index, daysIndex }))
									}
								>
									<CloseIcon />
								</IconButton>
							</Stack>
						</Stack>
					))}
				</Stack>
			))}
			<Stack p={2} height="56px" justifyContent="center" alignItems="flex-end">
				<Button onClick={() => dispatch(addOpeningConfigRow())} variant="linkDark">
					{tClientLabels('location-information.buttons.addNewOpeningHours')}
				</Button>
			</Stack>
		</>
	);
};

export default ChooseOpeningHour;
