import { AppDispatch, RootState } from 'store/store';
import {
	Box,
	Button,
	Dialog,
	IconButton,
	LinearProgress,
	Stack,
	Typography,
} from 'styles';
import { DragEvent, useMemo, useRef, useState } from 'react';
import { IUploadAction, UploadFileStep, iFile } from 'types/Upload';
import {
	deleteDocument,
	setFileUpload,
	setStep,
	setUploadPercentage,
	uploadDocument,
} from 'feature/upload/uploadSlice';
import {
	resetUploadFileDialogState,
	setDialogOpen,
} from 'feature/hooks/uploadFileDialogSlice';
import styled, { css } from 'styled-components';
import useDialogConfirm, { GENERIC_ERROR } from 'hooks/DialogConfirm.hook';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Camera } from 'assett/icons/camera_icon.svg';
import { ReactComponent as CloseDialog } from 'assett/icons/close_dialog_icon.svg';
import { ReactComponent as CloseOutlined } from 'assett/icons/close_outlined.svg';
import { ReactComponent as Folder } from 'assett/icons/folder_icon.svg';
import { ReactComponent as Landscape } from 'assett/icons/landscape_icon.svg';
import mobile from 'is-mobile';
import palette from 'styles/theme/Palette';
import { theme } from 'styles/theme/ThemeStyle';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { verifyAccept } from 'utils/download';

const MAX_SIZE_IN_BYTES = 20 * 1024 * 1024; // 20MB
const dragAndDropFileAccepted =
	'image/png, image/jpeg, image/jpg, application/pdf';

const UPLOAD_TABLET_ACTIONS: IUploadAction[] = [
	{
		icon: '',
		label: '',
		actionType: 'fromLibrary',
	},
	{
		icon: '',
		label: '',
		actionType: 'fromPhoto',
	},
	{
		icon: 'folder',
		label: '',
		actionType: 'fromFile',
	},
];

const UPLOAD_WEB_ACTIONS: IUploadAction[] = [
	{
		icon: 'folder',
		label: '',
		actionType: 'fromFile',
	},
];

const FileBox = styled(Button)<{ $isMobile: boolean; $errorDrag: boolean }>`
	display: flex;
	flex-direction: column;
	border-color: ${palette.primary.light};
	max-height: 8.75rem;
	border-radius: 0.5rem;
	width: 100%
		${({ $isMobile }) =>
			$isMobile &&
			css`
				height: 12.5rem;
				width: 12.5rem;
			`};
	${({ $errorDrag }) =>
		$errorDrag &&
		css`
			border-color: red;
		`};
`;

const UploadFileDialog = () => {
	const dialogConfirm = useDialogConfirm();
	const uploadDocumentPromise = useRef<any>(null);
	const onChooseFileBehavior = useRef<string>('*');
	const tablet = useMediaQuery(theme.breakpoints.down('lg'));
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const [dragActive, setDragActive] = useState(false);
	const [errorDrag, setErrorDrag] = useState(false);
	const { open } = useSelector(
		(store: RootState) => store.hooks.uploadFileDialog,
	);
	const isMobile = mobile({ tablet: true, featureDetect: true });
	const { uploadPercentage, step, documentTypeSelected } = useSelector(
		(store: RootState) => store.upload,
	);
	const documentData = useSelector(
		(store: RootState) => store.claim.claimData[documentTypeSelected],
	);

	const documentId = documentData?.id;

	const allowedActions = useMemo(
		() => (isMobile ? UPLOAD_TABLET_ACTIONS : UPLOAD_WEB_ACTIONS),
		[isMobile],
	);
	const isButtonDisabled = useMemo(
		() => [UploadFileStep.LOADING].includes(step),
		[step],
	);

	const handleFileChange = (
		event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
	) => {
		const handlerFile = (
			files: FileList | null,
			eventType: 'dragEvent' | 'changeEvent',
		) => {
			if (files) {
				const selectedFile = files[0];
				const accept =
					eventType === 'changeEvent'
						? (event as React.ChangeEvent<HTMLInputElement>).target.accept
						: dragAndDropFileAccepted;
				if (!verifyAccept(selectedFile.type, accept)) {
					event.preventDefault();
					dispatch(resetUploadFileDialogState());
					dialogConfirm.open(
						GENERIC_ERROR,
						`${tClientLabels('error.fileNotEnabled')}`,
					);
					return;
				}
				if (selectedFile?.size && selectedFile?.size > MAX_SIZE_IN_BYTES) {
					dispatch(resetUploadFileDialogState());
					dialogConfirm.open(
						GENERIC_ERROR,
						`${tClientLabels('error.fileSizeError', { size: '20' })}`,
					);
					return;
				}
				dispatch(setFileUpload(selectedFile));
				const promise = dispatch(
					uploadDocument({ showloader: false, docType: documentTypeSelected }),
				);
				uploadDocumentPromise.current = promise;
			}
		};
		if (event?.target && 'files' in event?.target) {
			const { files } = event.target;
			handlerFile(files, 'changeEvent');
		} else if ('dataTransfer' in event) {
			const { dataTransfer } = event;
			handlerFile(dataTransfer.files, 'dragEvent');
		}
	};

	const handleDrag = function (evt: DragEvent<HTMLDivElement>) {
		evt.preventDefault();
		evt.stopPropagation();

		if (evt.dataTransfer.items.length > 1) {
			setErrorDrag(true);
			evt.dataTransfer.effectAllowed = 'none';
			evt.dataTransfer.dropEffect = 'none';
		}

		if (evt.type === 'dragenter' || evt.type === 'dragover') {
			setDragActive(true);
		} else if (evt.type === 'dragleave') {
			setDragActive(false);
			setErrorDrag(false);
		}
	};

	const handleDrop = function (evt: DragEvent<HTMLDivElement>) {
		evt.preventDefault();
		evt.stopPropagation();
		if (evt.dataTransfer.items.length === 1) {
			setDragActive(false);
			handleFileChange(evt);
		} else {
			setErrorDrag(false);
		}
	};

	const getIcon = (actionType: iFile) => {
		switch (actionType) {
			case 'fromFile':
				onChooseFileBehavior.current =
					'image/png, image/jpeg, image/jpg, application/pdf';
				return (
					<Folder
						width={tablet ? '2rem' : '3rem'}
						height={tablet ? '2rem' : '3rem'}
					/>
				);
			case 'fromLibrary':
				onChooseFileBehavior.current = 'image/png, image/jpeg, image/jpg';
				return <Landscape />;
			case 'fromPhoto':
				onChooseFileBehavior.current = 'image/png, image/jpeg, image/jpg';
				return <Camera />;
			default:
				return <Folder />;
		}
	};

	const clearState = () => {
		dispatch(resetUploadFileDialogState());
		dispatch(setStep(UploadFileStep.CHOOSE));
		dispatch(setUploadPercentage(0));
	};

	const onConfirm = () => {
		clearState();
	};

	const onDelete = () => {
		if (documentId) {
			dispatch(deleteDocument({ id: documentId, docType: documentTypeSelected }));
		} else {
			// console.log('no docId found');
		}
	};

	const onCancel = () => {
		if (step === UploadFileStep.LOADING) {
			uploadDocumentPromise.current.abort();
		} else {
			onDelete();
		}
		clearState();
	};

	const abortCallAction = () => {
		uploadDocumentPromise.current.abort();
		dispatch(setStep(UploadFileStep.CHOOSE));
		dispatch(setUploadPercentage(0));
	};

	const handlerCloseIconBehavior = () => {
		switch (step) {
			case UploadFileStep.CHOOSE:
				dispatch(setDialogOpen(false));
				break;
			case UploadFileStep.LOADING:
				uploadDocumentPromise.current.abort();
				dispatch(setStep(UploadFileStep.CHOOSE));
				dispatch(setUploadPercentage(0));
				dispatch(setDialogOpen(false));
				break;
			case UploadFileStep.COMPLETE:
				onDelete();
				clearState();
				break;
		}
	};

	const abortUpload = () => {
		if (step === UploadFileStep.LOADING) {
			abortCallAction();
		} else {
			if (documentId) {
				dispatch(deleteDocument({ id: documentId, docType: documentTypeSelected }));
				dispatch(setStep(UploadFileStep.CHOOSE));
				dispatch(setUploadPercentage(0));
			}
		}
	};

	return (
		<Dialog
			title={tClientLabels(`uploadFileDialog.${[documentTypeSelected]}.title`)}
			close={
				<IconButton onClick={handlerCloseIconBehavior}>
					<CloseDialog />
				</IconButton>
			}
			open={open}
			variant="uploadFileDialog"
			events={step !== UploadFileStep.CHOOSE}
			actions={
				<>
					<Button variant="outlined" onClick={onCancel}>
						{tClientLabels(`uploadFileDialog.buttons.cancel`)}
					</Button>
					<Button
						disabled={isButtonDisabled}
						variant="contained"
						onClick={onConfirm}
					>
						{tClientLabels(`uploadFileDialog.buttons.confirm`)}
					</Button>
				</>
			}
			type=""
		>
			<Box display="flex" gap="1rem" flexDirection="column">
				<Typography variant="body">
					{tClientLabels(`uploadFileDialog.${[documentTypeSelected]}.description`)}
				</Typography>
				{step === UploadFileStep.CHOOSE ? (
					<Stack gap={1}>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="center"
							gap="0.938rem"
							sx={{ width: '100%' }}
							onDragEnter={handleDrag}
						>
							{allowedActions.map(action => (
								<FileBox
									key={action.actionType}
									component="label"
									variant="upload"
									$isMobile={isMobile}
									$errorDrag={errorDrag}
								>
									{getIcon(action.actionType)}
									{isMobile || errorDrag ? null : (
										<Typography variant="value3" style={{ textTransform: 'none' }}>
											{tClientLabels(`uploadFileDialog.dragAndDrop`)}
										</Typography>
									)}
									{errorDrag ? (
										<Typography
											variant="bodyLinkTablet"
											sx={{
												color: 'red',
												fontSize: { lg: '0.875rem', sm: '0.75rem' },
												textTransform: 'none',
											}}
										>
											Cannot upload more than one file
										</Typography>
									) : (
										<Typography
											variant="bodyLinkTablet"
											sx={{
												textDecoration: 'underline',
												textUnderlineOffset: '5px',
												fontSize: { lg: '0.875rem', sm: '0.75rem' },
												lineHeight: { lg: '1.05rem', sm: '1rem' },
											}}
										>
											{tClientLabels(`uploadFileDialog.actionType.${action.actionType}`)}
										</Typography>
									)}
									<input
										hidden
										id="fileInput"
										type="file"
										onChange={handleFileChange}
										accept={onChooseFileBehavior.current}
										capture={action.actionType === 'fromPhoto'}
									/>
									{dragActive && (
										<Box
											position="absolute"
											width="100%"
											height="100%"
											top={0}
											left={0}
											onDragOver={handleDrag}
											onDragLeave={handleDrag}
											onDrop={handleDrop}
										/>
									)}
								</FileBox>
							))}
						</Box>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							sx={{ width: '100%' }}
						>
							<Typography variant="value3">
								{tClientLabels(`uploadFileDialog.fileTypes`)}
							</Typography>
							<Typography variant="value3">
								{tClientLabels(`uploadFileDialog.size`)}
							</Typography>
						</Box>
					</Stack>
				) : (
					<Box>
						<Typography variant="caption" color={palette.grey2.main}>
							{`${tClientLabels(
								'uploadFileDialog.uploadPercentageLabel',
							)}: ${uploadPercentage}%`}
						</Typography>
						<Stack direction="row" alignItems="center" gap="1.188rem">
							<LinearProgress
								variant="determinate"
								value={uploadPercentage}
								sx={{ height: '0.375rem', borderRadius: '0.625rem', width: '100%' }}
							/>
							<IconButton onClick={abortUpload}>
								<CloseOutlined />
							</IconButton>
						</Stack>
					</Box>
				)}
			</Box>
		</Dialog>
	);
};

export default UploadFileDialog;
