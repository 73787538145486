import {
	editDocumentName,
	setDocumentId,
	setDocumentTypeSelected,
} from 'feature/upload/uploadSlice';
import { FC, useEffect, useState } from 'react';
import { Box, Button, Dialog, IconButton, Typography } from 'styles';

import { ReactComponent as CloseDialog } from 'assett/icons/close_dialog_icon.svg';
import { useFileOpener } from 'hooks/downloader.hook';
import { useUploadFile } from 'hooks/uploadFile.hook';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { IFileType } from 'types/Claim';
import { DocType } from 'types/Upload';
import { camelcaseToUnderscore } from 'utils/utils';
import BeforeUpload from './BeforeUpload';
import UploadedFileBox from './UploadFileBox';

const disallowedCharsRegex = /^[^\\/.*?]*$/;

type DocumentationClaimProps = {
	label: string;
	labelUpload: string;
	documentType: DocType;
	data?: IFileType;
};

const UploadFileTile: FC<DocumentationClaimProps> = ({
	label,
	documentType,
	data,
}) => {
	const [fileNameField, setFileNameField] = useState('');
	const [dialogFlag, setDialogFlag] = useState(false);
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const dispatch = useDispatch<AppDispatch>();
	const uploadFileHandler = useUploadFile();

	const documentId = data?.id;
	const fileName = data?.fileName;
	const [isEditMode, setIsEditMode] = useState(false);

	const onDelete = () => {
		uploadFileHandler.delete();
		setDialogFlag(false);
	};

	const handlerFileNameAction = () => {
		setIsEditMode(false);
		if (fileNameField && fileNameField.trim() !== fileName) {
			dispatch(
				editDocumentName({
					docType: documentType,
					documentId: documentId!,
					fileName: fileNameField.trim(),
				}),
			);
		}
	};

	const handleTextInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
		if (disallowedCharsRegex.test(evt.target.value)) {
			setFileNameField(evt.target.value);
		}
	};

	const fileOpener = useFileOpener();
	const handlerDownloadFile = () => {
		const url = `${
			process.env.REACT_APP_API_URL
		}/attachments/${camelcaseToUnderscore(documentType)}/${documentId}/content`;

		fileOpener(url, fileName!);
	};

	useEffect(() => setFileNameField(fileName?.split('.')[0] || ''), [fileName]);

	return (
		<>
			{!data?.id ? (
				<BeforeUpload documentType={documentType} />
			) : (
				<UploadedFileBox
					isEditMode={isEditMode}
					enableEditModeAction={() => setIsEditMode(true)}
					enableDialogAction={() => {
						setDialogFlag(true);
						dispatch(setDocumentId(documentId));
						dispatch(setDocumentTypeSelected(documentType));
					}}
					handlerFileName={handleTextInput}
					handlerFileNameAction={handlerFileNameAction}
					fileName={fileNameField}
					label={label}
					canDownloadFile
					handlerDownloadFile={handlerDownloadFile}
				/>
			)}
			<Dialog
				title={tClientLabels(`dialog.confirm.deleteUploadedFile.${documentType}`)}
				close={
					<IconButton onClick={() => setDialogFlag(false)}>
						<CloseDialog />
					</IconButton>
				}
				open={dialogFlag}
				variant="confirm"
				events
				actions={
					<>
						<Button variant="outlined" onClick={() => setDialogFlag(false)}>
							{tClientLabels(`dialog.buttons.cancel`)}
						</Button>
						<Button onClick={onDelete} variant="contained">
							{tClientLabels(`dialog.buttons.continue`)}
						</Button>
					</>
				}
				type=""
			>
				<Box display="flex" gap="1rem" flexDirection="column">
					<Typography variant="body">
						{tClientLabels('dialog.confirm.deleteUploadedFile.content', {
							docName: fileName,
						})}
					</Typography>
				</Box>
			</Dialog>
		</>
	);
};

export default UploadFileTile;
