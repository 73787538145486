import { FC, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	DatePicker,
	FormControl,
	Stack,
	StackRadio,
	Typography,
} from 'styles';
import { IClaim, IPrescription, PRESCRIPTION_DEFAULT } from 'types/Claim';

import SelectGeneric from 'components/commons/form/SelectGeneric';
import dayjs, { Dayjs } from 'dayjs';
import { getPrescriptions } from 'feature/claims/claimSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import palette from 'styles/theme/Palette';
import { PageProps } from './NewClaim.component';
import Prescription from './components/Prescription';

type IDoctorType = 'Optician' | 'Optometrist' | 'Ophthalmologist';

const Page1: FC<PageProps> = props => {
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const { t: tDbLabels } = useTranslation('', { keyPrefix: 'dbLabels' });
	const dispatch = useDispatch<AppDispatch>();
	const { formData, setFormData, submitPage } = props;
	const { doctorTypes } = useSelector((store: RootState) => store.claim);
	const [checkFields, setCheckFields] = useState<boolean>(false);
	const [newPresCompleted, setNewPresCompleted] = useState<boolean>(false);
	const [oldPresCompleted, setOldPresCompleted] = useState<boolean>(false);
	const [isChecked, setIsChecked] = useState<boolean>(
		formData.rxChangedWithoutOldPrescription || false,
	);
	const [doctorType, setDoctorType] = useState<IDoctorType | 'reset'>(
		(formData.newPrescriptionContent?.doctorTypology as IDoctorType) || 'reset',
	);
	const [prescriptionDate, setPrescriptionDate] = useState<
		Dayjs | null | string
	>(formData.newPrescriptionContent?.prescriptionDate || null);
	const [errorDate, setErrorDate] = useState<boolean>(false);
	const [errorDoctorType, setErrorDoctorType] = useState<boolean>(false);
	const [errorDateMessage, setErrorDateMessage] = useState<string>(
		'prescriptionDateError',
	);

	useEffect(() => {
		scrollWithError();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorDate, errorDoctorType]);

	const scrollWithError = () => {
		if (
			errorDate ||
			errorDoctorType ||
			(formData.rxChangeAttestation === 1 && !isChecked && !oldPresCompleted)
		) {
			const box = document.getElementById('page1-scroll');
			if (box) {
				if (formData.rxChangeAttestation === 0) {
					box.scrollTo({ top: 150, behavior: 'smooth' });
				} else if (formData.rxChangeAttestation === 1) {
					const topValue = isChecked ? 220 : !oldPresCompleted ? 220 : 710;
					box.scrollTo({ top: topValue, behavior: 'smooth' });
				}
			}
		}
	};

	const handleChangeRadio = (value: string) => {
		if (Number(value) === 1) {
			dispatch(getPrescriptions({}));
		}
		setErrorDate(false);
		setErrorDoctorType(false);
		setIsChecked(false);
		setFormData((prev: IClaim) => ({
			...prev,
			newPrescriptionContent: prev.newPrescriptionContent || {
				...PRESCRIPTION_DEFAULT,
			},
			oldPrescriptionContent:
				Number(value) === 1
					? {
						...PRESCRIPTION_DEFAULT,
					}
					: undefined,
			rxChangedWithoutOldPrescription: Number(value) === 1 ? false : null,
			rxChangeAttestation: Number(value) as 0 | 1,
		}));
	};

	const handleSubmit = () => {
		scrollWithError();
		//controllo per vedere se si tratta di una data precedente ai 45 giorni consentiti
		const isBefore45 = dayjs(prescriptionDate).isBefore(
			dayjs().subtract(45, 'day'),
		);
		//controllo per vedere se si tratta di una data futura
		const isNotValidDate = dayjs(prescriptionDate).isAfter(dayjs());
		if (
			newPresCompleted &&
			doctorType &&
			prescriptionDate &&
			!isBefore45 &&
			!isNotValidDate &&
			(oldPresCompleted ||
				formData.rxChangeAttestation === 0 ||
				formData.rxChangedWithoutOldPrescription)
		) {
			setCheckFields(false);
			submitPage!();
		} else {
			if (!doctorType || doctorType === 'reset') {
				setErrorDoctorType(true);
			}
			//se la data non è stata inserita
			if (!prescriptionDate) {
				setErrorDate(true);
				setErrorDateMessage('prescriptionDateError');
			}
			//se la data inserita è precedente ai 45 giorni
			if (isBefore45) {
				setErrorDate(true);
				setErrorDateMessage('prescriptionDateErrorBefore');
			}
			//se la data inserita è futura
			if (isNotValidDate) {
				setErrorDate(true);
				setErrorDateMessage('prescriptionDateErrorAfter');
			}
			setCheckFields(true);
		}
	};

	return (
		<>
			<Stack gap="10px" >
				<Typography variant="title6">
					{tClientLabels('newClaim.selectedPage.sectionTitile.enterPrescription')}
				</Typography>
				<StackRadio
					onChange={(_, value) => handleChangeRadio(value)}
					value={formData.rxChangeAttestation ?? null}
					label={tClientLabels('newClaim.prescription.hasOldPrescriptionQ')}
					options={[
						{
							label: tClientLabels(`newClaim.prescription.hasOldPrescriptionA.yes`),
							value: 1,
						},
						{
							label: tClientLabels(`newClaim.prescription.hasOldPrescriptionA.no`),
							value: 0,
						},
					]}
					variant="transparent"
				/>
			</Stack>

			{formData.rxChangeAttestation === 1 && (
				<Stack sx={{ mt: '20px', ml: '-6px' }}>
					<Checkbox
						label={tClientLabels('newClaim.prescription.prescriptionChanged')}
						checked={isChecked}
						onChange={(_, value) => {
							setIsChecked(value);
							setFormData((prev: IClaim) => ({
								...prev,
								oldPrescriptionContent: value
									? undefined
									: (prev.oldPrescriptionContent as IPrescription),
								rxChangedWithoutOldPrescription: value,
							}));
						}}
					/>
				</Stack>
			)}

			<Stack mt="20px" gap="20px">
				{(formData.rxChangeAttestation === 0 ||
					formData.rxChangeAttestation === 1) && (
						<>
							<Typography variant="body">
								{tClientLabels('newClaim.prescription.fillIn')}
							</Typography>
							{formData.rxChangeAttestation === 1 && !isChecked && (
								<Prescription
									presFilled={oldPresCompleted}
									key="old"
									label="newClaim.prescription.oldPrescriptionContent"
									data={formData.oldPrescriptionContent!}
									isNotChecked={!isChecked}
									setData={(value: IPrescription) => {
										setFormData((prev: IClaim) => ({
											...prev,
											oldPrescriptionContent: value,
										}));
									}}
									checkFields={checkFields}
									setRequiredFilled={setOldPresCompleted}
								/>
							)}
							<Stack
								flexDirection={'row'}
								mt={
									formData.rxChangeAttestation === 0 ||
										(formData.rxChangeAttestation === 1 && isChecked)
										? 0
										: 1.25
								}
							>
								<Stack>
									<SelectGeneric
										action={(payload: string) => {
											setDoctorType(payload as IDoctorType);
											setFormData((prev: IClaim) => ({
												...prev,
												newPrescriptionContent: {
													...prev.newPrescriptionContent,
													doctorTypology: payload,
												} as IPrescription,
											}));
											setErrorDoctorType(false);
										}}
										items={doctorTypes.map(type => ({
											value: type.name,
											text: tDbLabels(type.label),
										}))}
										name={'doctorType'}
										sx={{ width: { sm: 340, lg: 368 } }}
										initialValue={doctorType}
										error={errorDoctorType}
									/>
									{errorDoctorType && (
										<Typography variant="bodyLink" color={palette.error.main}>
											{tClientLabels(`newClaim.prescription.doctorTypeError`)}
										</Typography>
									)}
								</Stack>
								<FormControl sx={{ width: { sm: 340, lg: 368 }, marginLeft: 2 }}>
									<DatePicker
										variant="margin"
										label={tClientLabels('newClaim.prescription.dateOfPrescription')}
										value={dayjs(prescriptionDate)}
										onChange={value => {
											setErrorDate(false);
											setPrescriptionDate(value);
											setFormData((prev: IClaim) => ({
												...prev,
												newPrescriptionContent: {
													...prev.newPrescriptionContent,
													prescriptionDate: value?.format('YYYY-MM-DDTHH:mm[Z]'),
												} as IPrescription,
											}));
										}}
										error={errorDate}
									/>
									{errorDate && (
										<Typography variant="bodyLink" color={palette.error.main}>
											{tClientLabels(`newClaim.prescription.${errorDateMessage}`)}
										</Typography>
									)}
								</FormControl>
							</Stack>
							<Prescription
								presFilled={newPresCompleted}
								key="new"
								label={
									formData.rxChangeAttestation === 1
										? 'newClaim.prescription.newPrescriptionContent'
										: 'newClaim.prescription.Prescription'
								}
								data={formData.newPrescriptionContent!}
								setData={(value: IPrescription) => {
									setFormData((prev: IClaim) => ({
										...prev,
										newPrescriptionContent: value,
									}));
								}}
								checkFields={checkFields}
								setRequiredFilled={setNewPresCompleted}
							/>
							<Stack sx={{ mt: '10px', left: '-9px' }}>
								<Checkbox
									label={tClientLabels('newClaim.prescription.prescriptionConsent')}
									checked={!!formData.prescriptionConsent}
									onChange={(_, value) => {
										setFormData((prev: IClaim) => ({
											...prev,
											prescriptionConsent: value,
										}));
									}}
								/>
							</Stack>
						</>
					)}
			</Stack>
			<Box alignItems="start" mt="30px">
				<Button
					disabled={!formData.prescriptionConsent}
					variant="contained"
					sx={{ mt: '10px' }}
					onClick={handleSubmit}
				>
					{tClientLabels('newClaim.selectedPage.proceed')}
				</Button>
			</Box>
		</>
	);
};

export default Page1;
