import { Stack, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { PaymentDetailsType } from 'types/Payment';
import { Box100, Box130 } from '../ClaimsTable.component';
import { useTranslation } from 'react-i18next';

const TableHeader = () => {
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const theme = useTheme();
	const { paymentDetails } = useSelector((store: RootState) => store.payment);
	return (
		<Stack
			width={'100%'}
			display={'flex'}
			flexDirection={'row'}
			justifyContent={'space-between'}
			alignItems={'flex-start'}
			alignSelf={'stretch'}
			px={2.5}
			mb={1.875}
			gap={theme.spacing(1.25)}
		>
			<Box130>
				<Typography variant="bodyLink">
					{tClientLabels('payment.claimTable.claimId')}
				</Typography>
			</Box130>
			<Box130 sx={{ textAlign: 'center' }}>
				<Typography variant="bodyLink">
					{tClientLabels('payment.claimTable.dateOfService')}
				</Typography>
			</Box130>
			<Box100>
				<Typography variant="bodyLink">
					{tClientLabels('payment.claimTable.totalCharges')}
				</Typography>
			</Box100>
			<Box100>
				<Typography variant="bodyLink">
					{tClientLabels('payment.claimTable.contractualWriteOff')}
				</Typography>
			</Box100>
			<Box100>
				<Typography variant="bodyLink">
					{tClientLabels('payment.claimTable.memberResponsible')}
				</Typography>
			</Box100>
			<Box100>
				<Typography variant="bodyLink">
					{tClientLabels('payment.claimTable.claimPayment')}
				</Typography>
			</Box100>
			<Box100>
				<Typography variant="bodyLink">
					{tClientLabels('payment.claimTable.assessmentFee')}
				</Typography>
			</Box100>
			<Box100>
				<Typography variant="bodyLink">
					{tClientLabels('payment.claimTable.assessmentFeeTax')}
				</Typography>
			</Box100>
			<Box100>
				<Typography variant="bodyLink">
					{tClientLabels('payment.claimTable.other')}
				</Typography>
			</Box100>
			{paymentDetails.detailType === PaymentDetailsType.EOP && (
				<Box100>
					<Typography variant="bodyLink">
						{tClientLabels('payment.claimTable.netDue')}
					</Typography>
				</Box100>
			)}
			<Box100>
				<Typography variant="bodyLink">
					{tClientLabels('payment.claimTable.location')}
				</Typography>
			</Box100>
		</Stack>
	);
};

export default TableHeader;
