import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DocType, IUploadState, UploadFileStep } from 'types/Upload';
import {
	deleteDocumentThunk,
	editDocumentNameThunk,
	uploadDocumentThunk,
} from './uploadThunk';

const initialState: IUploadState = {
	step: UploadFileStep.CHOOSE,
	uploadPercentage: 0,
	errorCode: 0,
	errorMessage: '',
	documentTypeSelected: 'prescription',
	documentId: null,
	file: null,
	filePromise: null,
};

export const uploadDocument = createAsyncThunk(
	'upload/uploadDocument',
	uploadDocumentThunk,
);
export const deleteDocument = createAsyncThunk(
	'delete/deleteDocument',
	deleteDocumentThunk,
);
export const editDocumentName = createAsyncThunk(
	'edit/editDocumentName',
	editDocumentNameThunk,
);

const uploadSlice = createSlice({
	name: 'upload',
	initialState,
	reducers: {
		setDocumentTypeSelected: (state, { payload }: PayloadAction<DocType>) => {
			state.documentTypeSelected = payload;
			state.step = UploadFileStep.CHOOSE;
		},
		setFileUpload: (state, { payload }: PayloadAction<File>) => {
			state.file = payload;
		},
		setUploadPercentage: (state, { payload }: PayloadAction<number>) => {
			state.uploadPercentage = payload;
		},
		setStep: (state, step: PayloadAction<UploadFileStep>) => {
			state.step = step.payload;
		},
		setFilePromise: (state, { payload }: PayloadAction<any>) => {
			state.filePromise = payload;
		},
		setDocumentId: (state, { payload }: PayloadAction<any>) => {
			state.documentId = payload;
		},
		resetUpload: () => initialState,
	},
	extraReducers: builder => {
		builder
			.addCase(uploadDocument.fulfilled, (state, { payload }: any) => {
				state.step = UploadFileStep.COMPLETE;
				state.documentId = payload.id;
			})
			.addCase(uploadDocument.pending, state => {
				state.step = UploadFileStep.LOADING;
			})
			.addCase(uploadDocument.rejected, state => {
				return uploadSlice.caseReducers.resetUpload();
			})
			.addCase(deleteDocument.rejected, (state, { payload }: any) => {
				//console.log('deleteDocument.rejected', payload);
			})
			.addCase(editDocumentName.fulfilled, (state, { payload }: any) => {
				// console.log('editDocumentName.fulfilled', payload);
			})
			.addCase(editDocumentName.pending, (state, { payload }: any) => {
				// console.log('editDocumentName.pending', payload);
			})
			.addCase(editDocumentName.rejected, (state, { payload }: any) => {
				// console.log('editDocumentName.rejected', payload);
			});
	},
});

export const {
	setFileUpload,
	setUploadPercentage,
	setStep,
	setDocumentTypeSelected,
	resetUpload,
	setFilePromise,
	setDocumentId,
} = uploadSlice.actions;

export default uploadSlice.reducer;
