import {
	Checkbox,
	IconButton,
	Paper,
	Stack,
	Typography,
	UnstiledLink,
} from 'styles';
import { getShortDate } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ChevronRightIcon } from 'assett/icons/chevron_right_icon.svg';
import { FC } from 'react';
import { IClaims } from 'types/Claims';
import { RootState } from 'store/store';
import { StatusIcon } from './StatusIcon.component';
import { setSelectOne } from 'feature/claims/claimsSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ClaimsList: FC = () => {
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const { t: tDbLabels } = useTranslation('', { keyPrefix: 'dbLabels' });
	const { claims, claimIds } = useSelector((store: RootState) => store.claims);
	const navigate = useNavigate();
	const { isDesktop } = useDevice();

	const { features } = useSelector((store: RootState) => store.user);
	const featuresRole = features.map(el => el.code);

	const handleClick = (id: number) => {
		navigate(`/view-claims/${id}`);
	};

	const dispatch = useDispatch<any>();

	const List = claims.map((claim: IClaims) => {
		return (
			<Paper
				variant="outlined"
				sx={{
					borderWidth: 0,
					mb: 0.5,
					minWidth: 'fit-content',
					width: '100%',
				}}
				key={claim.claimId}
			>
				<Stack
					direction={'row'}
					alignItems={'center'}
					height={!!isDesktop ? 52 : 38}
					sx={{ p: !!isDesktop ? 1.25 : 0.8 }}
				>
					<Stack flexGrow={1} direction={'row'} alignItems={'center'}>
						<Checkbox
							label=""
							key={claim.claimId}
							sx={{ p: 0, ml: 0 }}
							checked={claimIds.includes(claim.claimId)}
							onClick={() => {
								dispatch(setSelectOne(claim.claimId));
							}}
						/>

						<Typography
							variant="body"
							sx={{ width: !!isDesktop ? 245 : 220, ml: 1.5 }}
						>
							{`#${claim.externalClaimId}`}
						</Typography>
						<Typography variant="body" sx={{ width: !!isDesktop ? 245 : 220 }}>
							{claim.memberFirstName}
						</Typography>
						<Typography variant="body" sx={{ width: !!isDesktop ? 245 : 220 }}>
							{claim.memberLastName}
						</Typography>
						<Typography variant="body" sx={{ width: !!isDesktop ? 336 : 220 }}>
							{getShortDate(claim.claimDate)}
						</Typography>

						{claim.status.id === 1 && featuresRole.includes('submit-claims') ? (
							<UnstiledLink
								variant="body"
								onClick={() => {
									navigate(
										`/check-and-submit/${claim.memberFullName}/new-claim?claimId=${claim.claimId}`,
									);
								}}
								sx={{ width: !!isDesktop ? 300 : 290 }}
							>
								<Typography variant="body">
									<StatusIcon status={claim.status.id} />
									{tDbLabels(claim.status.label)}
								</Typography>
							</UnstiledLink>
						) : (
							<Typography variant="body" sx={{ width: !!isDesktop ? 300 : 290 }}>
								<StatusIcon status={claim.status.id} />
								{tDbLabels(claim.status.label)}
							</Typography>
						)}
					</Stack>
					<Stack flexGrow={0}>
						<IconButton
							onClick={() => handleClick(claim.claimId)}
							sx={{ cursor: 'pointer' }}
						>
							<ChevronRightIcon />
						</IconButton>
					</Stack>
				</Stack>
			</Paper>
		);
	});

	const progressIndicator = useSelector(
		(state: RootState) => state.progressIndicator,
	);

	return (
		<Stack sx={{ mb: 0.5, width: '100%' }}>
			{List.length > 0 ? (
				List
			) : (
				<Typography variant="bodyLink" sx={{ width: '100%', padding: 1.2 }}>
					{progressIndicator?.isLoading
						? ''
						: tClientLabels('view-claims.noClaimsFound')}
				</Typography>
			)}
		</Stack>
	);
};
