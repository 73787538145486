import {
	changePage,
	downloadClaims,
	setClaimIds,
} from 'feature/claims/claimsSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Button from 'styles/button/Button';
import Pagination from 'styles/pagination/Pagination';
import Stack from 'styles/stack/Stack';

const ClaimListFooter = () => {
	const dispatch = useDispatch<any>();
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const { isDesktop } = useDevice();
	const { claimIds, pages, page, claims, downloadAll, claimExcludeIds } =
		useSelector((store: RootState) => store.claims);

	const refreshClaimIdsList = useMemo(
		() =>
			!claims.some(claim => claimExcludeIds.includes(claim.claimId)) &&
			downloadAll,
		[claimExcludeIds, claims, downloadAll],
	);

	const handleChangePage = (
		event: React.ChangeEvent<unknown>,
		value: number,
	) => {
		event.preventDefault();
		dispatch(changePage(value));
	};

	const handleClick = (type: string) => {
		dispatch(downloadClaims({ id: 0, type: type }));
	};

	useEffect(() => {
		if (refreshClaimIdsList) dispatch(setClaimIds(claims.map(e => e.claimId)));
	}, [claims, dispatch, refreshClaimIdsList]);

	return (
		<Stack
			direction={'row'}
			position={'relative'}
			sx={{ my: !!isDesktop ? 4.25 : 3 }}
			alignItems={'center'}
			justifyContent={'space-between'}
		>
			<Stack
				minHeight="24px"
				direction={'row'}
				flexGrow={1}
				sx={{
					justifyContent: !!isDesktop ? 'center' : 'flex-start',
					alignItems: 'center',
				}}
			>
				{pages > 1 ? (
					<Pagination page={page} count={pages} onChange={handleChangePage} />
				) : null}
			</Stack>
			<Stack
				direction={'row'}
				position={'absolute'}
				right={0}
				sx={{
					gap: 2,
					alignContent: 'center',
					justifyContent: 'flex-end',
				}}
			>
				<Button
					type="button"
					variant="contained"
					disabled={claimIds.length > 0 ? false : true}
					fullWidth={false}
					onClick={() => handleClick('XLSX')}
				>
					{tClientLabels('view-claims.exportButton.XLSX')}
				</Button>
				<Button
					type="button"
					variant="contained"
					disabled={claimIds.length > 0 ? false : true}
					fullWidth={false}
					onClick={() => handleClick('PDF')}
				>
					{tClientLabels('view-claims.exportButton.PDF')}
				</Button>
			</Stack>
		</Stack>
	);
};

export default ClaimListFooter;
