import { resetUploadFileDialogState } from 'feature/hooks/uploadFileDialogSlice';
import {
	deleteDocument,
	resetUpload,
	setFilePromise,
	setFileUpload,
	uploadDocument,
} from 'feature/upload/uploadSlice';
import {
	ChangeEvent,
	DragEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { DocType, UploadFileStep } from 'types/Upload';

const AUTHORIZED_FILE_EXTENSIONS = [
	'application/pdf',
	'image/jpeg',
	'image/jpg',
	'image/png',
];

const MAX_SIZE_IN_BYTES = 20 * 1024 * 1024; // 20MB

export function verifyAccept(type: string): boolean {
	return AUTHORIZED_FILE_EXTENSIONS.includes(type);
}

export const useUploadFile = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const { documentTypeSelected, step, documentId, filePromise } = useSelector(
		(store: RootState) => store.upload,
	);

	const [dragActive, setDragActive] = useState(false);
	const [dragError, setDragError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		if (!!dragError && errorMessage !== '') {
			setTimeout(() => {
				setDragError(false);
				setErrorMessage('');
			}, 5000);
		}
	}, [errorMessage, dragError]);

	const handlerFile = useCallback(
		(files: FileList | null, documentType: DocType) => {
			if (files && files.length !== 0) {
				const selectedFile = files[0];

				if (!verifyAccept(selectedFile.type)) {
					dispatch(resetUploadFileDialogState());
					setDragError(true);
					setErrorMessage(tClientLabels('error.fileNotEnabled') as string);
					return;
				}

				if (selectedFile?.size && selectedFile?.size > MAX_SIZE_IN_BYTES) {
					dispatch(resetUploadFileDialogState());
					setDragError(true);
					setErrorMessage(
						tClientLabels('error.fileSizeError', { size: '20' }) as string,
					);
					return;
				}

				dispatch(setFileUpload(selectedFile));
				const promise = dispatch(
					uploadDocument({ showloader: true, docType: documentType }),
				);
				dispatch(setFilePromise(promise));
			}
		},
		[dispatch, tClientLabels],
	);

	const handleUpload = useCallback(
		(
			event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
			documentType: DocType,
		) => {
			if (event?.target && 'files' in event.target) {
				const { files } = event.target;
				event.preventDefault();

				handlerFile(files, documentType);
			} else if ('dataTransfer' in event) {
				const { dataTransfer } = event;
				handlerFile(dataTransfer.files, documentType);
			}
		},
		[handlerFile],
	);

	const handleDelete = useCallback(() => {
		if (documentId) {
			dispatch(deleteDocument({ id: documentId, docType: documentTypeSelected }));
		}
	}, [dispatch, documentId, documentTypeSelected]);

	const handleAbort = useCallback(() => {
		if (step === UploadFileStep.LOADING) {
			filePromise?.abort();
		} else {
			handleDelete();
		}

		dispatch(resetUpload());
	}, [dispatch, filePromise, handleDelete, step]);

	const handleDrag = (evt: DragEvent<HTMLDivElement>) => {
		evt.preventDefault();
		evt.stopPropagation();

		if (evt.dataTransfer.items.length === 1) {
			if (!verifyAccept(evt.dataTransfer.items[0].type)) {
				dispatch(resetUploadFileDialogState());
				setDragError(true);
				setErrorMessage(tClientLabels('error.fileNotEnabled') as string);
			}
		}

		if (evt.dataTransfer.items.length > 1) {
			setDragError(true);
			setErrorMessage(tClientLabels('newClaim.upload.oneFileOnly') as string);
			evt.dataTransfer.effectAllowed = 'none';
			evt.dataTransfer.dropEffect = 'none';
		}

		if (evt.type === 'dragenter' || evt.type === 'dragover') {
			setDragActive(true);
		} else if (evt.type === 'dragleave') {
			setDragActive(false);
			setDragError(false);
		}
	};

	const handleDrop = (
		evt: DragEvent<HTMLDivElement>,
		callBack: (
			event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
		) => void,
	) => {
		evt.preventDefault();
		evt.stopPropagation();
		if (evt.dataTransfer.items.length === 1) {
			setDragActive(false);
			callBack(evt);
		} else {
			setDragError(false);
		}
	};

	return useMemo(
		() => ({
			upload: handleUpload,
			delete: handleDelete,
			abort: handleAbort,
			drag: handleDrag,
			drop: handleDrop,
			dragStatus: {
				isActive: dragActive,
				onError: dragError,
				errorMessage,
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			handleUpload,
			handleDelete,
			handleAbort,
			dragActive,
			dragError,
			errorMessage,
		],
	);
};
