import { AppDispatch, RootState } from 'store/store';
import { Box, Button, Stack, Typography } from 'styles';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
	LocationFIeld,
	OpeningDaily,
	OpeningsHour,
} from 'types/LocationInformation';
import {
	getAvailableConfig,
	getLocationInfo,
	resetState,
	saveLocationInfo,
	setLocationFields,
} from 'feature/locationInformation/locationInformationSlice';
import useDialogConfirm, { GENERIC_ERROR } from 'hooks/DialogConfirm.hook';
import { useDispatch, useSelector } from 'react-redux';

import { CardMedia } from '@mui/material';
import ChooseOpeningHour from './ChooseOpeningsHour';
import { ReactComponent as Clock } from 'assett/icons/clock.svg';
import EditConfiguration from './EditConfiguration';
import FieldLocation from './FieldLocation';
import { ReactComponent as GoToMyessilor } from 'assett/icons/go_to_essilor_tablet_icon.svg';
import { ReactComponent as GoToMyessilorDisabled } from 'assett/icons/go_to_essilor_disabled_icon.svg';
import LocationInformationImage from 'assett/icons/location_information_image.svg';
import { ReactComponent as Marker } from 'assett/icons/marker.svg';
import PrintConfiguration from './PrintConfiguration';
import { ReactComponent as Telephone } from 'assett/icons/telephone.svg';
import { numericValueToTimeString } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LocationInformation = () => {
	const [editMode, setEditMode] = useState(false);
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const navigate = useNavigate();
	const dialogConfirm = useDialogConfirm();

	const { currentConfiguration, locationFields, openingsHour, locationName } =
		useSelector((store: RootState) => store.locationInformation);
	const dispatch = useDispatch<AppDispatch>();

	const handlerLocationFields = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = evt.target;
		dispatch(
			setLocationFields({ text: value, fieldName: name as LocationFIeld }),
		);
	};

	const createOpeningsHourText = useCallback(() => {
		return openingsHour
			.map((config: OpeningsHour) => {
				const openingDays = config.days
					.map((day: number) =>
						tClientLabels(`commons.daysOfWeek.d${day}`).substring(0, 3),
					)
					.join('-');
				const openingHours = config.openings
					.map(
						(hour: OpeningDaily) =>
							`${numericValueToTimeString(hour.open)}-${numericValueToTimeString(
								hour.close,
							)}`,
					)
					.join(' ');
				return `${openingDays} ${openingHours}`.trim() === '-'
					? ''
					: `${openingDays} ${openingHours}`;
			})
			.join(', ');
	}, [openingsHour, tClientLabels]);

	const checkRequiredFields = () => {
		const blankOpening = openingsHour.find(
			el =>
				!el.days.length ||
				el.openings.find(
					el => typeof el.open !== 'number' || typeof el.close !== 'number',
				),
		);
		return !blankOpening;
	};

	const [checkFields, setCheckFields] = useState(false);
	useEffect(() => {
		if (!editMode) setCheckFields(false);
	}, [editMode]);

	const onConfirm = () => {
		if (checkRequiredFields()) {
			setEditMode(false);
			dispatch(saveLocationInfo({ showloader: true }));
		} else {
			setCheckFields(true);
			dialogConfirm.open(
				GENERIC_ERROR,
				`${tClientLabels('error.pleaseCheckRequired')}`,
			);
		}
	};

	useEffect(() => {
		dispatch(getLocationInfo({ showloader: true }));
		dispatch(getAvailableConfig({ showloader: true }));
		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	return (
		<Fragment>
			<Box sx={{ height: '100%' }}>
				<Stack
					direction={'row'}
					height={'100%'}
					justifyContent={'space-between'}
					overflow="hidden"
				>
					<Box
						pr={1}
						height={'98%'}
						width={{ sm: '100%', lg: 900 }}
						sx={{ overflowY: 'auto' }}
					>
						<Stack
							sx={{
								mt: { xs: 0.625 },
								mb: 2,
								width: '100%',
							}}
							rowGap={!editMode ? { sm: '1.875rem', lg: '1.9rem' } : '1.875rem'}
						>
							<Box>
								<Box
									display="flex"
									alignItems="center"
									width="100%"
									height={{ sm: '100%', lg: '7.125rem' }}
								>
									<Typography variant="title5">{locationName}</Typography>
									<Stack ml="auto" direction="row" gap="0.75rem">
										{editMode ? (
											<>
												<Button
													onClick={() => {
														dispatch(getLocationInfo({ showloader: true }));
														dispatch(getAvailableConfig({ showloader: true }));
														setEditMode(false);
													}}
													variant="outlined"
												>
													{tClientLabels('location-information.buttons.cancel')}
												</Button>
												<Button onClick={onConfirm} variant="contained">
													{tClientLabels('location-information.buttons.confirm')}
												</Button>
											</>
										) : (
											<Button onClick={() => setEditMode(true)} variant="contained">
												{tClientLabels('location-information.buttons.edit')}
											</Button>
										)}
									</Stack>
								</Box>
								<Stack mt={{ sm: '1.25rem', lg: '0rem' }} gap="0.625rem">
									<FieldLocation
										handlerTextField={handlerLocationFields}
										fieldName="address1"
										isEditMode={editMode}
										icon={<Marker />}
										text={locationFields['address1']}
									/>
									<FieldLocation
										handlerTextField={handlerLocationFields}
										fieldName="phoneNumber"
										isEditMode={editMode}
										icon={<Telephone />}
										text={locationFields['phoneNumber']}
									/>
									{!editMode ? (
										<FieldLocation
											handlerTextField={handlerLocationFields}
											icon={<Clock />}
											text={createOpeningsHourText()}
										/>
									) : (
										<ChooseOpeningHour checkFields={checkFields} />
									)}
									<FieldLocation
										handlerTextField={handlerLocationFields}
										fieldName="websiteUrl"
										isEditMode={editMode}
										icon={editMode ? <GoToMyessilorDisabled /> : <GoToMyessilor />}
										text={locationFields['websiteUrl']}
										isDisabled={editMode}
									/>
								</Stack>
								{!editMode && (
									<Stack
										mt={{ sm: '0.625rem', lg: '0.938rem' }}
										gap="0.625rem"
										direction="row"
										alignItems="center"
										justifyContent="flex-end"
									>
										<Typography variant="value3">
											{tClientLabels('location-information.link.title')}
										</Typography>
										<Button onClick={() => navigate('/contact-us')} variant="linkDark">
											{tClientLabels('location-information.link.label')}
										</Button>
									</Stack>
								)}
							</Box>
							{!editMode ? (
								<>
									<Box
										display="flex"
										flexDirection="row"
										gap={{ sm: '1.25rem', lg: '2.5rem' }}
										width="100%"
									>
										<PrintConfiguration
											width="auto"
											label={tClientLabels('location-information.labels.lang')}
											data={currentConfiguration.languages}
										/>
										<PrintConfiguration
											label={tClientLabels('location-information.labels.prod')}
											data={currentConfiguration.products}
										/>
									</Box>
									<PrintConfiguration
										label={tClientLabels('location-information.labels.services')}
										data={currentConfiguration.services}
									/>
									<PrintConfiguration
										label={tClientLabels('location-information.labels.brands')}
										data={currentConfiguration.brands}
									/>
								</>
							) : (
								<Stack gap={{ sm: '1.438rem', lg: '1.875rem' }}>
									<EditConfiguration
										configurationKey="languages"
										label={tClientLabels('location-information.labels.lang')}
										inputPlaceholder={tClientLabels(
											'location-information.inputPlaceholder.addLang',
										)}
									/>
									<EditConfiguration
										configurationKey="products"
										label={tClientLabels('location-information.labels.prod')}
										inputPlaceholder={tClientLabels(
											'location-information.inputPlaceholder.addProd',
										)}
									/>
									<EditConfiguration
										configurationKey="services"
										label={tClientLabels('location-information.labels.services')}
										inputPlaceholder={tClientLabels(
											'location-information.inputPlaceholder.addService',
										)}
									/>
									<EditConfiguration
										configurationKey="brands"
										label={tClientLabels('location-information.labels.brands')}
										inputPlaceholder={tClientLabels(
											'location-information.inputPlaceholder.addBrand',
										)}
									/>
								</Stack>
							)}
						</Stack>
					</Box>
					<Stack
						height={'100%'}
						alignSelf={'end'}
						justifyContent={'end'}
						alignItems={'end'}
						display={{ sm: 'none', lg: 'flex' }}
						sx={{ width: { sx: '90%', lg: 677 } }}
					>
						<CardMedia
							component="img"
							sx={{
								width: { sx: 371.95, lg: '100%' },
								mb: -3.8,
							}}
							image={LocationInformationImage}
						/>
					</Stack>
				</Stack>
			</Box>
		</Fragment>
	);
};

export default LocationInformation;
