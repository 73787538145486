// import { format } from "date-fns";

import { IFilterCheckEligibility, IFilterState } from 'types/Form';

import dayjs from 'dayjs';
import { IMemberSubscriptions } from 'types/Members';
import { getLanguageStorage } from './localStorage';
import { FieldAmount, IAmountLimit } from 'types/Member';

export const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

export const capitalize = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const camelcase = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const camelcaseToUnderscore = (str: string) => {
	if (!str) return '';
	return str
		.split(/(?=[A-Z])/)
		.join('_')
		.toLowerCase();
};

export const pxToRem = (px: number) => {
	return px / 16;
};

export const getStatusString = (string: string) => {
	switch (string) {
		case 'NEW':
		case 'WAITING_FEEDBACK':
		case 'APPROVED':
			return 'Pending';
		case 'MEMBER_PAID':
			return 'Paid';
		case 'COMPLETED':
			return 'Completed';
		case 'CANCELED':
			return 'Canceled';
	}
};

export const getShortDate = (date?: string) => {
	if (!date) return '';
	var d = new Date(date);
	d = new Date(d.getTime() + d.getTimezoneOffset() * 60000);

	return d.toLocaleDateString(getLanguageStorage(), {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});
};

export const getShortTime = (date?: string) => {
	if (!date) return '';
	var d = new Date(date);
	d = new Date(d.getTime() + d.getTimezoneOffset() * 60000);

	return d
		.toLocaleTimeString([], {
			hour: '2-digit',
			minute: '2-digit',
			hour12: true,
		})
		.toUpperCase();
};

export const getFullDate = (date?: string) => {
	if (!date) return '';

	return `${getShortDate(date)} ${getShortTime(date)}`;
};

export const formatDayjsForBE = (date: dayjs.Dayjs | null) => {
	return dayjs(date).format('YYYY-MM-DD');
};

export const formatDateWithoutZulu = (date: string) => {
	return date.substring(0, date.search('T'));
};

export const dayjsForMaterial = (date?: dayjs.Dayjs | null) => {
	if (!date) return undefined;
	return date
		.set('hour', 0)
		.set('minute', 0)
		.set('second', 0)
		.set('millisecond', 0);
};

export const formatPrice = (
	number: number | string,
	showCurrency: boolean = true,
) => {
	const n = Number(number);
	return new Intl.NumberFormat(getLanguageStorage(), {
		style: 'currency',
		currency: 'EUR',
	})
		.format(n)
		.replace('-', '- ')
		.replace('€', showCurrency ? '€ ' : '');
};

export const formatNumber = (value: string, isCurrency: boolean) => {
	const pattern = /^(:?(?:[+-]?[0-9]+([.,])?([0-9]+)??)+[+-]?)$/;

	if (value.match(pattern)) {
		let returnValue = value.replaceAll(/,/g, '.');
		const dotPosition = returnValue.search(/\./);

		if (dotPosition !== -1) {
			//se sono stati inseriti più punti andiamo ad eliminare la seconda occorrenza
			if (returnValue.split('').filter(v => v === '.')?.length > 1) {
				const newString = returnValue
					.substring(dotPosition + 1, returnValue.length)
					.replace('.', '');
				returnValue = returnValue.substring(0, dotPosition + 1) + newString;
			}
			//se sono stati inseriti 3 caratteri dopo il punto andiamo ad eliminare l'ultimo carattere
			if (dotPosition + 3 < value.length) {
				returnValue = returnValue.substring(0, dotPosition + 3);
			}
		}

		if (isCurrency) {
			returnValue = returnValue.replace('+', '').replace('-', '');
		}

		return returnValue;
	}

	return '';
};

export const formatInputNumberValue = (value: string | number) => {
	const lng = getLanguageStorage();

	if (lng.toLowerCase() === 'en-gb' || lng.toLowerCase() === 'en-us') {
		return value.toString().replace(',', '.');
	} else {
		return value.toString().replace('.', ',');
	}
};

export const copyToClipboard = (text: string) => {
	navigator.clipboard.writeText(text);
};

export const getLocaleLanguage = () => {
	return getLanguageStorage().substring(0, 2);
};

export const createValueSearchMember = (memberValues: IFilterState) => {
	const {
		inputFiscalCode,
		inputMemberId,
		inputFirstName,
		inputLastName,
		inputDateOfBirth,
		dateOfService,
	} = memberValues;

	let values;
	if (inputFiscalCode !== '') {
		values = {
			size: 10,
			fiscal_code: inputFiscalCode,
			date_of_service: dateOfService,
		};
	}
	if (inputMemberId !== '') {
		values = {
			size: 10,
			member_id: inputMemberId,
			date_of_service: dateOfService,
		};
	}
	if (
		inputFirstName !== '' &&
		inputLastName !== '' &&
		inputDateOfBirth !== null
	) {
		values = {
			size: 10,
			first_name: inputFirstName,
			last_name: inputLastName,
			birth_date: inputDateOfBirth,
			date_of_service: dateOfService,
		};
	}

	return values;
};

export const createClaimsSearchValues = (
	claimsSearchValues: IFilterCheckEligibility,
) => {
	const {
		inputClaimId,
		inputExternalClaimId,
		inputFirstName,
		inputLastName,
		dateOfService,
		inputClaimStatus,
	} = claimsSearchValues;
	let searchValues = '';
	if (inputClaimId) {
		searchValues = searchValues.concat(`&claimId=${inputClaimId}`);
	}
	if (inputExternalClaimId) {
		searchValues = searchValues.concat(
			`&externalClaimId=${inputExternalClaimId}`,
		);
	}
	if (inputFirstName) {
		searchValues = searchValues.concat(
			`&firstName=${inputFirstName.replaceAll(' ', '%20')}`,
		);
	}
	if (inputLastName) {
		searchValues = searchValues.concat(
			`&lastName=${inputLastName.replaceAll(' ', '%20')}`,
		);
	}
	if (dateOfService) {
		searchValues = searchValues.concat(
			`&serviceDate=${formatDayjsForBE(dateOfService)}`,
		);
	}
	if (inputClaimStatus) {
		searchValues = searchValues.concat(`&claimStatus=${inputClaimStatus}`);
	}
	return searchValues;
};

export const createPaymentsSearchValues = (
	paymentsSearchValues: IFilterCheckEligibility,
) => {
	const { inputPaymentRunId, inputTotalAmount, dateOfService } =
		paymentsSearchValues;

	let searchValues = '';
	if (inputPaymentRunId) {
		searchValues = searchValues.concat(
			`&paymentRunId=${encodeURIComponent(inputPaymentRunId)}`,
		);
	}
	if (inputTotalAmount) {
		searchValues = searchValues.concat(`&totalAmount=${inputTotalAmount}`);
	}
	if (dateOfService) {
		searchValues = searchValues.concat(
			`&paymentRunDate=${formatDayjsForBE(dateOfService)}`,
		);
	}
	return searchValues;
};

export const numericValueToTimeString = (value: number | undefined): string => {
	if (typeof value === 'number') {
		const hours = Math.floor(value);
		const minutes = (value - hours) * 60;
		const formattedMinutes = Math.round(minutes);
		return `${hours}:${formattedMinutes.toString().padStart(2, '0')}`;
	} else return '';
};

export const isSubscriptionValid = (
	sub: IMemberSubscriptions,
	inputMemberId: string,
	dateOfService: string,
) => {
	// we want to check the subscriber and external id only if we are searching with the memberId
	if (inputMemberId && inputMemberId !== '') {
		if (
			sub.subscriberMemberId !== inputMemberId &&
			sub.externalMemberId !== inputMemberId
		) {
			return false;
		}
	}

	return (
		dayjs(dateOfService).isBetween(sub.validFrom, sub.validTo, 'day', '[]') ||
		dayjs(sub.validFrom).isSame(dateOfService) ||
		dayjs(sub.validFrom).isAfter(dateOfService)
	);
};

/**
 * Paginate array
 * @param array List of element to be paginated
 * @param page_size PAGE_SIZE const
 * @param page_number Page number requested
 * @returns the slice of array corresponding to that page
 */

export const paginate = (
	array: any[], // TODO: fix any, use generic type array
	pageSize: number,
	pageNumber: number,
) => {
	return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

export const getRangeValueAmount = (
	serviceId: number | null,
	benefit: { amountLimits: IAmountLimit[]; serviceId: number }[],
	field: FieldAmount,
) => {
	const values = benefit
		.find(b => b.serviceId === serviceId)
		?.amountLimits.find(amount => amount.field === field);
	return { max: values?.maxLength };
};
