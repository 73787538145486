import {
	downloadPaymentClaim,
	downloadPaymentGroup,
} from 'feature/payments/paymentsSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Stack } from 'styles';

type IType = 'claim' | 'group';

const DownloadButton = ({ type }: { type: IType }) => {
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const dispatch = useDispatch<any>();
	const location = useLocation();
	const pathnames = location.pathname.split('/');
	const claimId = pathnames[pathnames.length - 1];
	const param = useParams();

	const handleClick = () => {
		if (type === 'claim') {
			dispatch(
				downloadPaymentClaim({
					paymentRunId: param.id?.split('|')[0] as string,
					claimId,
				}),
			);
		} else {
			const formattedType =
				pathnames[pathnames.length - 1] === 'group-invoice-assessment'
					? 'GROUP_INVOICE'
					: 'EOP';
			dispatch(
				downloadPaymentGroup({
					paymentRunId: param.id?.split('|')[0] as string,
					paymentRunDate: param.id?.split('|')[1] as string,
					type: formattedType,
				}),
			);
		}
	};

	return (
		<Stack>
			<Button
				type="button"
				variant="contained"
				fullWidth={false}
				onClick={handleClick}
			>
				{tClientLabels('claim.button.download')}
			</Button>
		</Stack>
	);
};

export default DownloadButton;
