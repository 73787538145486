import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	downloadClaimsThunk,
	getClaimsByProviderThunk,
	getClaimsStatusThunk,
} from './claimsThunk';

import { IState } from 'types/Claims';

const initialState: IState = {
	claims: [],
	change: false,
	claimIds: [],
	claimExcludeIds: [],
	downloadAll: false,
	pages: 1,
	page: 1,
	search: false,
	claimsStatus: [],
};

export const getClaims = createAsyncThunk(
	'claims/getClaims',
	getClaimsByProviderThunk,
);

export const getClaimsStatus = createAsyncThunk(
	'claims/getClaimsStatus',
	getClaimsStatusThunk,
);

export const downloadClaims = createAsyncThunk(
	'claims/downloadClaims',
	downloadClaimsThunk,
);

const claimsSlice = createSlice({
	name: 'claims',
	initialState,
	reducers: {
		setSearchValue: (state: any, { payload }: any) => {
			const { name, value } = payload;
			state[name] = value;
		},
		setChangeFalse: state => {
			state.change = false;
		},
		setClaimIds: (state, { payload }: PayloadAction<number[] | []>) => {
			if (payload.length > 0) {
				state.claimIds = state.claimIds
					.concat(payload)
					.filter(
						(value: number, index: number, self: number[]) =>
							self.indexOf(value) === index,
					);
			} else {
				state.claimIds = [];
			}
		},
		setClaimExcludeIds: (state, { payload }: PayloadAction<number[] | []>) => {
			state.claimExcludeIds = payload;
		},
		setDownloadAll: (state: any, { payload }: PayloadAction<boolean>) => {
			state.downloadAll = payload;
		},
		setSelectOne: (state, { payload }: PayloadAction<number>) => {
			if (state.claimIds.includes(payload)) {
				const list = state.claimIds.filter((e: number) => e !== payload);
				state.claimIds = list;
				if (state.downloadAll) {
					state.claimExcludeIds.push(payload);
				}
			} else {
				state.claimIds.push(payload);
				state.claimExcludeIds = state.claimExcludeIds.filter(
					(id: number) => id !== payload,
				);
			}
		},
		changePage: (state: any, { payload }: PayloadAction<number>) => {
			state.page = payload;
		},
		setSearch: (state: any, { payload }: PayloadAction<boolean>) => {
			state.search = payload;
		},
		resetClaims: (state: any) => ({
			...state,
			initialState,
			claimsStatus: state.claimsStatus,
		}),
	},
	extraReducers: builder => {
		builder
			.addCase(getClaims.fulfilled, (state, { payload }) => {
				state.claims = payload.claims;
				state.change = true;
				state.pages = payload.pages;
			})
			.addCase(getClaims.rejected, (state, { payload }: any) => {
				state.claims = [];
			})
			.addCase(getClaimsStatus.fulfilled, (state, { payload }) => {
				state.claimsStatus = [...payload].sort((a, b) => (a.id > b.id ? 1 : -1));
			})
			.addCase(getClaimsStatus.rejected, (state, { payload }: any) => {
				state.claimsStatus = [];
			});
	},
});

export const {
	resetClaims,
	setChangeFalse,
	setDownloadAll,
	setSelectOne,
	changePage,
	setSearchValue,
	setSearch,
	setClaimIds,
	setClaimExcludeIds,
} = claimsSlice.actions;
export default claimsSlice.reducer;
