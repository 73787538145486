import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { AppDispatch, RootState } from 'store/store';
import { getLocaleTranslations } from 'feature/textsLocales/textsLocalesSlice';
import { getProfile, getRoles, logout } from 'feature/user/userSlice';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useCallback, useEffect } from 'react';
import useDialogLocation, { FIRST_LOCATION } from './DialogLocation.hook';
import { useDispatch, useSelector } from 'react-redux';

import { getCookiesContent } from 'feature/cookies/cookiesSlice';
import { getFooterLinks } from 'feature/footer/footerSlice';
import { healthCheck } from 'feature/utils/checkConnectionSlice';
import { msalConfig } from 'config/msalConfig';
import { getClaimsStatus } from 'feature/claims/claimsSlice';
import { cleanupStorage } from 'utils/localStorage';

const findRightAccount = (accounts: AccountInfo[]) =>
	accounts.find(el => el.idTokenClaims?.aud === msalConfig.auth.clientId);

export const useLogin = () => {
	const { instance, accounts, inProgress } = useMsal();
	const account = useAccount();

	if (!account) {
		const rightAccount = findRightAccount(accounts);
		rightAccount && instance.setActiveAccount(rightAccount);
	}

	useEffect(() => {
		if (inProgress === InteractionStatus.None && !findRightAccount(accounts)) {
			const tokenRequest = {
				scopes: [],
			};
			cleanupStorage();
			instance.acquireTokenRedirect(tokenRequest);
		}
	}, [accounts, inProgress, instance]);
};

export const useLogout = () => {
	const { instance, accounts } = useMsal();
	const dispatch = useDispatch<AppDispatch>();

	return useCallback(() => {
		dispatch(logout({ instance, accounts }));
	}, [accounts, dispatch, instance]);
};

export const useCheckAlive = () => {
	const isAuthenticated = useIsAuthenticated();
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		if (isAuthenticated) {
			dispatch(healthCheck(''));
		}
	}, [dispatch, isAuthenticated]);
};

export const useGetLocales = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { alive } = useSelector((state: RootState) => state.healthCheck);

	useEffect(() => {
		if (alive) {
			dispatch(getLocaleTranslations(''));
			dispatch(getClaimsStatus(''));
		}
	}, [dispatch, alive]);
};

export const useGetRoles = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { profile } = useSelector((store: RootState) => store.user);

	useEffect(() => {
		if (profile) {
			dispatch(getRoles(''));
		}
	}, [dispatch, profile]);
};

export const useCookies = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { errorMessages } = useSelector(
		(state: RootState) => state.textsLocales,
	);
	const { cookiesConfirmed } = useSelector((state: RootState) => state.user);

	useEffect(() => {
		if (errorMessages && !cookiesConfirmed) {
			//cookies could be confirmed from local storage
			dispatch(getCookiesContent(''));
		}
	}, [dispatch, errorMessages, cookiesConfirmed]);
};

export const useGetProfile = () => {
	const dispatch = useDispatch<AppDispatch>();
	const dialogLocation = useDialogLocation();

	const { profile, cookiesConfirmed } = useSelector(
		(store: RootState) => store.user,
	);
	const { errorMessages } = useSelector(
		(state: RootState) => state.textsLocales,
	);

	useEffect(() => {
		if (errorMessages && cookiesConfirmed) {
			dispatch(getProfile(''));
		}
	}, [dispatch, cookiesConfirmed, errorMessages]);

	useEffect(() => {
		if (cookiesConfirmed && profile && !profile.location) {
			dialogLocation.open(FIRST_LOCATION);
		}
	}, [profile, dialogLocation, cookiesConfirmed]);

	useEffect(() => {
		if (profile) {
			dispatch(getFooterLinks(''));
		}
	}, [dispatch, profile]);
};
