import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const PaymentTo = () => {
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const theme = useTheme();

	const { paymentDetails } = useSelector((store: RootState) => store.payment);

	return (
		<Stack
			display={'flex'}
			flexDirection={'column'}
			alignItems={'flex-start'}
			flex={'1 0 0'}
			gap={theme.spacing(2.5)}
		>
			<Box
				display={'flex'}
				justifyContent={'space-between'}
				alignItems={'flex-start'}
				alignSelf={'stretch'}
				py={0.625}
				px={2.5}
				borderRadius={'4px'}
				bgcolor={theme.palette.white.main}
			>
				<Typography variant="body">
					{tClientLabels('payment.details.to')}:
				</Typography>
				<Typography variant="bodyLink">{paymentDetails.to.name}</Typography>
			</Box>
			<Box
				display={'flex'}
				justifyContent={'space-between'}
				alignItems={'flex-start'}
				alignSelf={'stretch'}
				py={0.625}
				px={2.5}
			>
				<Typography variant="body">
					{tClientLabels('payment.details.address')}:
				</Typography>
				<Typography variant="bodyLink">{paymentDetails.to.address}</Typography>
			</Box>
			<Box
				display={'flex'}
				justifyContent={'space-between'}
				alignItems={'flex-start'}
				alignSelf={'stretch'}
				py={0.625}
				px={2.5}
			>
				<Typography variant="body">
					{tClientLabels('payment.details.tin')}:
				</Typography>
				<Typography variant="bodyLink">{paymentDetails.to.tin}</Typography>
			</Box>
		</Stack>
	);
};

export default PaymentTo;
