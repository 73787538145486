import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Paper, Stack, Typography } from 'styles';
import PaymentStack from 'styles/stack/PaymentStack';
import { PaymentDetailsType } from 'types/Payment';
import { formatPrice } from 'utils/utils';

const Ticket = () => {
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const { paymentDetails } = useSelector((store: RootState) => store.payment);

	const EOPAmount = (
		<>
			<Typography variant="body">
				{tClientLabels('payment.details.ticketAmount')}:
			</Typography>
			<Typography variant="price1">
				{formatPrice(paymentDetails.paymentAmount.amount)}
			</Typography>
		</>
	);

	const Total = (
		<>
			<Typography variant="body">
				{tClientLabels('payment.details.ticketTotal')}:
			</Typography>
			<Typography variant="price1">
				{formatPrice(paymentDetails.paymentAmount.total)}
			</Typography>
		</>
	);

	return (
		<>
			<Stack>
				<Paper elevation={0} variant="payment" className="roundBottom">
					<PaymentStack
						flexGrow={1}
						direction={'row'}
						pt={3.125}
						pb={2.5}
						sx={{ borderBottom: '1px dashed #bbbbbb' }}
					>
						<Typography variant="title6">
							{tClientLabels('payment.details.ticketTitle')}
							{paymentDetails.paymentAmount.paymentId}
						</Typography>
					</PaymentStack>
				</Paper>
				{paymentDetails.detailType !== PaymentDetailsType.EOP && (
					<Paper elevation={0} variant="payment" className="roundAll">
						<Stack display={'flex'} flexGrow={1} gap={'15px'} pt={1.875}>
							<Stack flexGrow={1} direction={'row'} justifyContent={'space-between'}>
								<Typography variant="body">
									{tClientLabels('payment.details.unitPrice')}:
								</Typography>
								<Typography variant="bodyLink">
									{formatPrice(paymentDetails.paymentAmount.unitPrice)}
								</Typography>
							</Stack>
							<Stack flexGrow={1} direction={'row'} justifyContent={'space-between'}>
								<Typography variant="body">
									{tClientLabels('payment.details.ticketAmount')}:
								</Typography>
								<Typography variant="bodyLink">
									{formatPrice(paymentDetails.paymentAmount.amount)}
								</Typography>
							</Stack>
							<Stack flexGrow={1} direction={'row'} justifyContent={'space-between'}>
								<Typography variant="body">
									{tClientLabels('payment.details.subtotal')}:
								</Typography>
								<Typography variant="bodyLink">
									{formatPrice(paymentDetails.paymentAmount.subTotal)}
								</Typography>
							</Stack>
							<PaymentStack
								flexGrow={1}
								direction={'row'}
								justifyContent={'space-between'}
								pb={1.875}
								sx={{ borderBottom: '1px dashed #bbbbbb' }}
							>
								<Typography variant="body">
									{tClientLabels('payment.details.vat')}:
								</Typography>
								<Typography variant="bodyLink">
									{formatPrice(paymentDetails.paymentAmount.vat)}
								</Typography>
							</PaymentStack>
						</Stack>
					</Paper>
				)}
				<Paper elevation={0} variant="payment" className="roundTop">
					<Stack
						display={'flex'}
						flexDirection={'row'}
						flexGrow={1}
						justifyContent={'space-between'}
						alignItems={'center'}
						alignSelf={'stretch'}
						py={1.875}
					>
						{paymentDetails.detailType === PaymentDetailsType.EOP && <>{EOPAmount}</>}
						{paymentDetails.detailType !== PaymentDetailsType.EOP && <>{Total}</>}
					</Stack>
				</Paper>
			</Stack>
		</>
	);
};

export default Ticket;
