import { FC, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import Box from 'styles/box/Box';
import Button from 'styles/button/Button';
import DatePickerField from 'styles/datePicker/DatePickerField';
import { RootState } from 'store/store';
import Stack from 'styles/stack/Stack';
import TextField from 'styles/textfield/TextField';
import { resetInputDateOfBirth } from 'feature/form/formSlice';
import palette from 'styles/theme/Palette';
import { resetMembers, searchMembers } from 'feature/members/membersSlice';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'hooks/previous.hook';
import { resetMember } from 'feature/members/memberSlice';

type TValues = {
	firstName: string | null;
	lastName: string | null;
	inputDateOfBirth: dayjs.Dayjs | null;
};

const FormMemberData: FC = () => {
	const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
	const dispatch = useDispatch<any>();
	const [value, setValue] = useState<TValues>({
		firstName: null,
		lastName: null,
		inputDateOfBirth: null,
	});
	const prevUserData = usePrevious<TValues>(value);
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const { dateOfService } = useSelector((store: RootState) => store.form);
	const prevInputDateOfBirth = usePrevious(value.inputDateOfBirth);
	const { members } = useSelector((store: RootState) => store.members);

	const showCta = useMemo(
		() => !members?.hits || members?.hits?.length === 0,
		[members?.hits],
	);

	const handleOnSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		if (!dateOfService) return;
		const target = e.target as typeof e.target & {
			inputFirstName: { value: string };
			inputLastName: { value: string };
		};
		dispatch(
			searchMembers({
				values: {
					size: 10,
					date_of_service: dayjs(dateOfService),
					first_name: target.inputFirstName.value,
					last_name: target.inputLastName.value,
					birth_date: dayjs(value.inputDateOfBirth).format('YYYY-MM-DD'),
				},
			}),
		);
	};

	useEffect(() => {
		if (
			!!value?.firstName &&
			!!value?.lastName &&
			!!value?.inputDateOfBirth &&
			!!dateOfService
		) {
			setDisabledSubmit(false);
		} else {
			setDisabledSubmit(true);
		}
	}, [value, dateOfService, value.inputDateOfBirth]);

	useEffect(() => {
		if (
			members?.hits?.length &&
			members?.hits?.length > 0 &&
			(prevUserData?.firstName !== value.firstName ||
				prevUserData?.lastName !== value.lastName ||
				!prevInputDateOfBirth?.isSame(value.inputDateOfBirth))
		) {
			dispatch(resetMembers());
			dispatch(resetMember());
		}
	}, [
		dispatch,
		value.inputDateOfBirth,
		members?.hits?.length,
		prevInputDateOfBirth,
		prevUserData,
		value,
	]);

	useEffect(() => {
		return () => dispatch(resetInputDateOfBirth());
	}, [dispatch]);

	return (
		<Box component={'form'} onSubmit={handleOnSubmit}>
			<Stack rowGap={{ sm: 2.5, lg: 4.125 }}>
				<TextField
					autoComplete={'off'}
					name="inputFirstName"
					type="text"
					label={tClientLabels('check-and-submit.searchLabels.firstName')}
					error={members?.total?.value === 0}
					sx={{
						borderColor:
							members?.total?.value === 0
								? palette.error.main
								: value.firstName?.length
								? palette.primary.main
								: 'none',
					}}
					onChange={e =>
						setValue(state => ({ ...state, firstName: e.target.value }))
					}
				/>
				<TextField
					autoComplete={'off'}
					name="inputLastName"
					type="text"
					label={tClientLabels('check-and-submit.searchLabels.lastName')}
					error={members?.total?.value === 0}
					sx={{
						borderColor:
							members?.total?.value === 0
								? palette.error.main
								: value.lastName?.length
								? palette.primary.main
								: 'none',
					}}
					onChange={e => setValue(state => ({ ...state, lastName: e.target.value }))}
				/>
				<DatePickerField
					value={value.inputDateOfBirth}
					onChange={(date: dayjs.Dayjs | null) =>
						setValue(state => ({ ...state, inputDateOfBirth: date }))
					}
					label={tClientLabels('check-and-submit.searchLabels.dateOfBirth')}
					error={members?.total?.value === 0}
					errorText={tClientLabels('check-and-submit.errors.memberData')}
					maxDate={dayjs()}
				/>
			</Stack>
			{showCta && (
				<Button
					variant="contained"
					type="submit"
					sx={{
						width: { sm: 112, lg: 116 },
						mt: {
							sm: members?.total?.value === 0 ? '54px' : '24px',
							lg: '50px',
						},
						justifyContent: 'center',
					}}
					disabled={disabledSubmit}
				>
					{tClientLabels('commons.buttons.search')}
				</Button>
			)}
		</Box>
	);
};

export default FormMemberData;
