import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { RootState } from 'store/store';
import { Typography } from 'styles';

const MemberDoctorType = () => {
	const { member } = useSelector((store: RootState) => store.member);

	return (
		<Typography variant="body">
			{member?.doctorTypologies.length === 1 ? (
				<Trans
					i18nKey={'clientLabels.memberDetail.labels.limitationNoticeType1'}
					values={{
						doctorType1: member?.doctorTypologies[0],
					}}
					components={{ bold: <Typography variant={'value2'} /> }}
				/>
			) : member?.doctorTypologies.length === 2 ? (
				<Trans
					i18nKey={'clientLabels.memberDetail.labels.limitationNoticeType2'}
					values={{
						doctorType1: member?.doctorTypologies[0],
						doctorType2: member?.doctorTypologies[1],
					}}
					components={{ bold: <Typography variant={'value2'} /> }}
				/>
			) : (
				<Trans
					i18nKey={'clientLabels.memberDetail.labels.limitationNoticeType3'}
					values={{
						doctorType1: member?.doctorTypologies[0],
						doctorType2: member?.doctorTypologies[1],
						doctorType3: member?.doctorTypologies[2],
					}}
					components={{ bold: <Typography variant={'value2'} /> }}
				/>
			)}
		</Typography>
	);
};

export default MemberDoctorType;
