import {
	setDialogLocationOpen,
	setInjectionState,
} from 'feature/hooks/locationSlice';
import { setActiveButton } from 'feature/user/userSlice';
import { useCallback, useMemo } from 'react';
import { AppDispatch, store } from 'store/store';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const FIRST_LOCATION = 'firstLocation';
export const CHANGE_LOCATION = 'changeLocation';

const useDialogLocation = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });

	const mapping = useCallback(
		(type: string) => {
			switch (type) {
				case FIRST_LOCATION:
					return {
						title: tClientLabels('dialog.firstLocation.title'),
						labelButtonSave: tClientLabels('dialog.buttons.proceed'),
						buttonClose: false,
					};
				case CHANGE_LOCATION:
					return {
						title: tClientLabels('dialog.changeLocation.title'),
						labelButtonSave: tClientLabels('dialog.buttons.save'),
						labelButtonCancel: tClientLabels('dialog.buttons.cancel'),
						buttonClose: false,
						buttonCancel: true,
					};
				default:
					break;
			}
		},
		[tClientLabels],
	);

	const closeDialogLocation = useCallback(() => {
		store.dispatch(setDialogLocationOpen(false));
		store.dispatch(setActiveButton(false));
	}, []);

	const cancelDialogLocation = useCallback(() => {
		store.dispatch(setDialogLocationOpen(false));
	}, []);

	const handleOpen = useCallback(
		(type: string) => {
			dispatch(setInjectionState(mapping(type)));
			store.dispatch(setDialogLocationOpen(true));
		},
		[dispatch, mapping],
	);

	return useMemo(
		() => ({
			open: handleOpen,
			close: closeDialogLocation,
			cancel: cancelDialogLocation,
		}),
		[cancelDialogLocation, closeDialogLocation, handleOpen],
	);
};

export default useDialogLocation;
