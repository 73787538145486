import { useMediaQuery, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Button from 'styles/button/Button';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';

type ContainerFooter = {
	ml?: string;
};

export const FooterDesktop: React.FC<ContainerFooter> = props => {
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const year = new Date().getFullYear();
	const theme = useTheme();
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));
	const { links } = useSelector((store: RootState) => store.footer);

	const openInNewTab = (url: string) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<Stack
			sx={{ borderTop: 1, ml: !!props.ml ? props.ml : 0 }}
			direction={'row'}
			height={!!desktop ? '70px' : '60px'}
			alignItems={'center'}
		>
			<Stack flexGrow={1}>
				<Typography variant="caption">{`© ${year} ${tClientLabels(
					'sharedLayout.footerRightReserved',
				)}`}</Typography>
			</Stack>
			<Stack flexGrow={0} direction={'row'} gap={!!desktop ? 4.75 : 2.5}>
				{links.map((l: any, index: number) => {
					return (
						<Button variant="link" onClick={() => openInNewTab(l.url)} key={index}>
							<Typography variant="caption">{l.label}</Typography>
						</Button>
					);
				})}
			</Stack>
		</Stack>
	);
};
